import React, {useEffect} from "react";

const F2 = () => {


  useEffect(() => {
    window.location.href = "https://tdtukokand.uz/";
  }, []);
  return (
    <>
    </>
  );
};

export default F2;
