import React, {useEffect} from "react";

const F1 = () => {

  useEffect(() => {
    window.location.href = "https://tdtuof.uz/";
  }, []);
  return (
    <>

    </>
  );
};

export default F1;
