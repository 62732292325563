import React, {useEffect} from "react";

function XalqaroHamkorlik() {

  useEffect(() => {
    window.location.href = "https://interdep.tdtu.uz/";
  }, []);
  return (
    <>

    </>
  );
}

export default XalqaroHamkorlik;
